<template>
    <div class="main">
        <div class="er-wei-ma">
            <div class="qrcode" ref="qrCodeUrl"></div>
            <div class="er-wei-ma-font1" v-if="downloadShown" @click="returnList">
                <img src="@/assets/img/return.png" />
                <span>返回列表</span>
            </div>
            <div class="er-wei-ma-font2" v-if="downloadShown" @click="download">
                <img src="@/assets/img/word.png" />
                <span>下载word</span>
            </div>
            <div
                class="er-wei-ma-font3"
                v-if="!downloadShown && list.length > 0"
                @click="batchDownWord"
            >
                <img src="@/assets/img/word.png" />
                <span>批量下载</span>
            </div>
        </div>
        <div class="result-tab">
            <div class="tab-left">
                <div
                    style="position: absolute; top: 0px; z-index: 999"
                    :class="key == 1 ? 'active' : ''"
                >
                    <div class="text"> 法律法规 </div>
                    <div class="ty"></div>
                </div>
            </div>
            <div class="tab-right">
                <Law class="tabShow" @getList="getList" ref="law"></Law>
            </div>
        </div>
    </div>
</template>

<script>
    import Law from '@/components/PC/result/Law.vue'
    export default {
        name: 'result',
        components: {
            Law,
        },
        watch: {
            '$route.query': function (newVal, oldVal) {
                this.downloadShown = false
                if (newVal.provisionIdList) {
                    this.downloadShown = true
                } else {
                    this.downloadShown = false
                }
            },
        },
        data() {
            return {
                key: 1,
                downloadShown: false,
                list: [],
            }
        },
        mounted() {
            if (this.$route.query.provisionIdList) {
                this.downloadShown = true
            } else {
                this.downloadShown = false
            }
        },
        methods: {
            getList(data) {
                this.list = data
            },
            download() {
                this.$refs.law.downLoadWord()
            },
            batchDownWord() {
                this.$refs.law.batchDownWord()
            },
            returnList() {
                this.$router.go(-1)
            },
        },
    }
</script>

<style lang="less">
    .qrcode {
        display: inline-block;
        img {
            width: 1.13rem;
            height: 1.13rem;
            background-color: #fff; //设置白色背景色
            padding: 0.06rem; // 利用padding的特性，挤出白边
            box-sizing: border-box;
        }
    }
</style>

<style scoped lang="less">
    .main {
        width: 16rem;
        margin: 0.2rem auto 0;
    }
    .result-tab {
        width: 16rem;
        .tab-left {
            width: 0.6rem;
            position: relative;
        }
        .tab-right {
            width: 15.4rem;
        }
        .text {
            position: relative;
            width: 0.6rem;
            padding: 0.24rem 0.18rem;
            background: #1cd4bd;
            font-size: 0.24rem;
            color: #fff;
            border-radius: 12px 0 0 12px;
            z-index: 9999;
            margin-bottom: -0.2rem;
        }
        .text:after {
            position: absolute;
            content: '';
            left: 0.06rem;
            bottom: -0.2rem;
            border-top: 0.21rem solid #1cd4bd;
            border-left: 0.54rem solid transparent;
            border-right: 0px solid transparent;
        }
        .ty {
            width: 0.7rem;
            height: 0.05rem;
            background: transparent;
            box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4);
            transform: rotate(205deg);
            border-radius: 12px;
            margin-top: 0.38rem;
        }
        .active {
            .text {
                background: #ffa525;
            }
            .text:after {
                border-top: 0.21rem solid #ffa525;
            }
        }
    }

    @keyframes orbit {
        0% {
            opacity: 1;
            z-index: 99;
            transform: rotate(180deg);
            animation-timing-function: ease-out;
        }

        7% {
            opacity: 1;
            transform: rotate(300deg);
            animation-timing-function: linear;
            origin: 0%;
        }

        30% {
            opacity: 1;
            transform: rotate(410deg);
            animation-timing-function: ease-in-out;
            origin: 7%;
        }

        39% {
            opacity: 1;
            transform: rotate(645deg);
            animation-timing-function: linear;
            origin: 30%;
        }

        70% {
            opacity: 1;
            transform: rotate(770deg);
            animation-timing-function: ease-out;
            origin: 39%;
        }

        75% {
            opacity: 1;
            transform: rotate(900deg);
            animation-timing-function: ease-out;
            origin: 70%;
        }

        76% {
            opacity: 0;
            transform: rotate(900deg);
        }

        100% {
            opacity: 0;
            transform: rotate(900deg);
        }
    }

    .er-wei-ma {
        position: fixed;
        right: 2px;
        top: 1.15rem;

        .er-wei-ma-font1 {
            cursor: pointer;
            width: 1.18rem;
            height: 0.48rem;
            background: #358cf3;
            border-bottom: 1px solid rgba(255, 255, 255, 0.6);
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0px 10px 0px 0;
            padding: 0 0.1rem;
            letter-spacing: 1px;
            // flex-direction: column;
            margin-top: -0.08rem;
            img {
                width: 0.28rem;
            }
            span {
                font-size: 0.16rem;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.22rem;
            }
        }

        .er-wei-ma-font2 {
            cursor: pointer;
            width: 1.18rem;
            height: 0.48rem;
            background: #358cf3;
            padding: 0 0.1rem;
            border-radius: 0px 0px 0px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // flex-direction: column;

            img {
                width: 0.22rem;
                margin-left: 2px;
            }
            span {
                font-size: 0.16rem;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.22rem;
            }
        }

        .er-wei-ma-font3 {
            cursor: pointer;
            width: 1.18rem;
            height: 0.48rem;
            background: #358cf3;
            padding: 0 0.1rem;
            border-radius: 0px 10px 0px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // flex-direction: column;

            img {
                width: 0.22rem;
                margin-left: 2px;
            }
            span {
                font-size: 0.16rem;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.22rem;
            }
        }
    }
</style>
