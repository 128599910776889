<template>
    <div class="law-content">
        <div class="docs">
            <div v-if="docs">
                <div class="docs-div resume_preview_page" id="docs">
                    <h1 class="docs-title">
                        {{ docs.lawName }}
                    </h1>
                    <block
                        class="docs-list"
                        v-for="(item, index) in docs.provisionList"
                        :key="index"
                    >
                        <div>
                            <h3 class="provisionName">{{ item.provisionName }}</h3></div
                        >
                        <div class="provisionText" v-html="item.provisionText"></div>
                    </block>
                </div>
            </div>
            <div v-if="list && list.length > 0">
                <a-checkbox-group @change="getIdList">
                    <div class="box" v-for="(item, index) in list" :key="index">
                        <div class="title">
                            <div class="left"
                                ><img src="@/assets/img/law-icon.png" alt="" />{{
                                    item.lawName
                                }}</div
                            >

                            <a-checkbox :value="item.provisionIdList.join(',')"
                        /></div>
                        <div
                            class="cont"
                            v-if="item.provisionName"
                            @click="toDetail(item.provisionIdList)"
                        >
                            <span>{{ item.provisionName }}</span>
                            <img src="@/assets/img/more-icon.png" alt="" />
                        </div>
                    </div>
                </a-checkbox-group>
            </div>
            <div
                v-if="!docs && list && list.length == 0"
                style="padding-top: 2.2rem; transform: scale(1.5)"
            >
                <a-empty description="暂无相关法律法规关联" />
            </div>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'

    export default {
        name: 'Law',
        data() {
            return {
                list: null,
                docs: null,
                checkIds: '',
            }
        },
        watch: {
            '$route.query': function (newVal, oldVal) {
                this.list = []
                this.docs = null
                if (newVal.provisionIdList) {
                    this.getDetail()
                } else {
                    this.getList()
                }
            },
        },
        mounted() {
            if (this.$route.query.provisionIdList) {
                this.getDetail()
            } else {
                this.getList()
            }
        },
        methods: {
            async getList() {
                this.list = null
                //请求数据
                let data = {
                    method: 'GET',
                    url: 'f/provision/getProvisionList?resultId=' + this.$route.query.resultId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.status == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.status == 200) {
                    this.list = dataSource.data
                    this.$emit('getList', this.list)
                }
            },
            toDetail(provisionIdList) {
                this.$router.push({
                    name: 'Result',
                    query: {
                        resultId: this.$route.query.resultId,
                        provisionIdList: provisionIdList.join(','),
                    },
                })
            },

            async getDetail() {
                this.list = null
                //请求数据
                let data = {
                    method: 'POST',
                    url: 'f/provision/getProvisionDetail',
                    config: {
                        data: {
                            provisionIdList: this.$route.query.provisionIdList.split(','),
                        },
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.status == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.status == 200) {
                    dataSource.data.provisionList.forEach((el) => {
                        el.provisionText = el.provisionText.replace(/\n/g, '<p>')
                    })
                    this.docs = dataSource.data
                    // dataSource.data.replace('/n', '</p>')
                    // this.docs = dataSource.data
                }
            },
            //下载word
            async downLoadWord() {
                let name = this.docs.lawName
                let formdata = new FormData()
                console.log(typeof this.$route.query.provisionIdList.split(','))
                formdata.append('provisionIdList', this.$route.query.provisionIdList.split(',')) //sdf.html是设置文件名
                let xhr = new XMLHttpRequest()
                xhr.open('POST', 'api/f/provision/downWord', true)
                xhr.setRequestHeader('Authentication', localStorage.getItem('token'))
                xhr.responseType = 'blob'
                xhr.onload = function () {
                    if (this.status == 200) {
                        let blob = new Blob([this.response], { type: this.response.type })
                        let downloadUrl = URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = downloadUrl
                        a.download = name + '.doc'
                        document.body.appendChild(a)
                        a.click()
                    }
                }
                xhr.send(formdata)
            },
            //下载word模板html
            getHtmlContent() {
                //获取html另外一种方式：this.$el.outerHTML
                const template = document.getElementById('docs').innerHTML
                let html = `<!DOCTYPE html>
                <html xmlns:v='urn:schemas-microsoft-com:vml'xmlns:o='urn:schemas-microsoft-com:office:office'xmlns:w='urn:schemas-microsoft-com:office:word'xmlns:m='http://schemas.microsoft.com/office/2004/12/omml'xmlns='http://www.w3.org/TR/REC-html40'  xmlns='http://www.w3.org/1999/xhtml' >
                <head>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width,initial-scale=1.0">
                    <title>X-Find迅聘选才</title>
                    <link rel="stylesheet" href="https://cdn.bootcss.com/iview/2.14.0/styles/iview.css" />
                    <!--[if gte mso 9]>
                    <xml>
                    <w:WordDocument><w:View>Print</w:View><w:TrackMoves>false</w:TrackMoves><w:TrackFormatting/><w:ValidateAgainstSchemas/><w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid><w:IgnoreMixedContent>false</w:IgnoreMixedContent><w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText><w:DoNotPromoteQF/><w:LidThemeOther>EN-US</w:LidThemeOther><w:LidThemeAsian>ZH-CN</w:LidThemeAsian><w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript><w:Compatibility><w:BreakWrappedTables/><w:SnapToGridInCell/><w:WrapTextWithPunct/><w:UseAsianBreakRules/><w:DontGrowAutofit/><w:SplitPgBreakAndParaMark/><w:DontVertAlignCellWithSp/><w:DontBreakConstrainedForcedTables/><w:DontVertAlignInTxbx/><w:Word11KerningPairs/><w:CachedColBalance/><w:UseFELayout/></w:Compatibility><w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel><m:mathPr><m:mathFont m:val='Cambria Math'/><m:brkBin m:val='before'/><m:brkBinSub m:val='--'/><m:smallFrac m:val='off'/><m:dispDef/><m:lMargin m:val='0'/> <m:rMargin m:val='0'/><m:defJc m:val='centerGroup'/><m:wrapIndent m:val='1440'/><m:intLim m:val='subSup'/><m:naryLim m:val='undOvr'/></m:mathPr></w:WordDocument>
                    </xml>
                    <![endif]-->
                </head>
                <body>
                    <span class="resume_preview_page" style="margin:0 auto;width:1200px">
                    ${template}
                    </span>
                </body>
                </html>`
                return html
            },
            getIdList(e) {
                // console.log(e);
                this.checkIds = e.join(',')
            },

            async batchDownWord() {
                if (!this.checkIds) {
                    this.$message.warning('请至少选择一篇法规')
                    return
                }
                let formData = new FormData()
                formData.append('provisionIdList', this.checkIds.split(','))
                let data = {
                    method: 'POST',
                    url: 'f/provision/batchDownWord',
                    config: {
                        data: formData,
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    },

                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.status == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.status == 200) {
                    const blob = new Blob([dataSource.data], {
                        type: 'application/zip',
                    })
                    let fileName = '推送法规'
                    this.download(blob, fileName)
                }
            },

            download(blob, fileName) {
                let isIE = window.navigator && window.navigator.msSaveOrOpenBlob
                if (isIE) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName)
                    return
                }
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'block'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) //释放URL对象
                document.body.removeChild(elink)
            },
        },
    }
</script>

<style scoped lang="less" type="text/less">
    .law-content {
        word-break: break-all;
        width: 15.4rem;
        margin: 0.2rem auto 0.2rem 0.6rem;
        text-align: left;
        line-height: 0.32rem;
        font-size: 0.16rem;
        padding: 0.3rem;
        background: rgba(27, 104, 205, 0.5);
        height: 78vh;
        overflow: hidden;
        .docs {
            float: left;
            position: relative;
            width: 14.8rem;
            background: #fff;
            height: 72vh;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.06rem;
                height: 0.06rem;
                background-color: #e7e7e7;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #358cf3;
            }

            /deep/.ant-checkbox-group {
                width: 100%;
            }
            .box {
                border-bottom: 2px solid #358cf3;
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                .title {
                    line-height: 1;
                    padding: 0.2rem 0.3rem 0.2rem;

                    font-size: 0.2rem;
                    color: #358cf3;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left {
                        margin-right: 30px;
                        flex: 1;
                    }

                    img {
                        width: 0.22rem;
                        margin-right: 0.1rem;
                    }

                    span {
                        font-size: 0.16rem;
                        line-height: 0.26rem;
                        background: #358cf3;
                        border-radius: 4px;
                        color: #fff;
                        padding: 0.02rem 0.08rem;
                        margin-left: 0.14rem;
                    }
                }

                .cont {
                    padding: 0.15rem 0.3rem;
                    background: #f5f9fe;
                    line-height: 0.3rem;
                    font-size: 0.16rem;
                    color: #4f555b;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    span {
                        flex: 1;
                    }

                    img {
                        width: 0.18rem;
                        height: 0.2rem;
                        margin-left: 0.1rem;
                    }
                }
            }
            .bottom-button {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 0.2rem 0;

                button {
                    line-height: 0.32rem;
                }
            }
            .docs-div {
                .docs-title {
                    font-size: 0.26rem;
                    font-weight: bold;
                    text-align: center;
                    padding: 0.36rem 0 0.24rem;
                }
                .docs-list {
                    .provisionName {
                        padding: 0 0.4rem;
                        margin-bottom: 0.1rem;
                        text-indent: 0.32rem;
                        font-size: 0.16rem;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                    .provisionText {
                        padding: 0 0.4rem;
                        margin-bottom: 0.1rem;
                        text-indent: 0.32rem;
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
</style>
